import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '@react-hookz/web'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { useIsomorphicLayoutEffect } from 'bl-common/src/hooks/useIsomorphicLayoutEffect'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

import { CardCollectionCard } from './CardCollectionCard'

type SidebarProps = {
  image?: string
}

type ImageDecoratorProps = {
  gradient?: any
}

const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing[2]};
  position: relative;

  ${media.mlg(css`
    display: flex;
    padding-top: ${({ theme }) => theme.spacing[4]};
  `)}
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  flex: 1 1 50%;
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};
`

const StickyContent = styled.div`
  ${media.md(css`
    max-width: ${mixins.span({ columns: 5, gutters: 5 })};
  `)}
`

const Sidebar = styled.aside<SidebarProps>`
  position: relative;
  flex: 1 0 50%;
  z-index: 2;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    ${({ image }) => css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
    `};
  }
`

const ImageDecorator = styled.div<ImageDecoratorProps>`
  position: absolute;
  width: 100%;
  min-height: 70%;
  top: ${({ theme }) => theme.spacing[-6]};
  left: ${({ theme }) => theme.spacing[9.5]};
  bottom: ${({ theme }) => theme.spacing[23]};
  z-index: 1;

  ${media.md(css`
    left: ${({ theme }) => theme.spacing[17.5]};
    bottom: ${({ theme }) => theme.spacing[9.5]};
  `)}

  ${({
    gradient = {
      fields: {},
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return (
      color1 &&
      color2 &&
      css`
        background: linear-gradient(${color1}, ${color2});
      `
    )
  }};
`

const Cards = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`

const LinkArrowWrapper = styled.div`
  margin-left: 0.5em;
  transition: transform ${durations.medium}ms ease;
`

const ArrowLink = styled(Link)`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  color: ${colors.deepBlue};

  .t-dark & {
    color: white;
  }
  .t-dark & path {
    fill: white;
  }

  &:hover,
  &:focus {
    ${LinkArrowWrapper} {
      transform: translate3d(0.5em, 0, 0);
    }
  }
`

export const CardCollection = ({
  title,
  description,
  link,
  gradient,
  cards,
  shouldContentScroll,
}) => {
  const { width } = useWindowSize()
  const { isMobile } = useBreakpoints()
  const shouldScroll = width >= breakpoints.mlg && shouldContentScroll

  const [bounds, setBounds] = useState([0, 0])
  const [offset, setOffset] = useState(0)
  const [isFixed, setIsFixed] = useState(false)

  const stickyParentRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function checkScroll() {
      if (shouldScroll) {
        if (window.scrollY >= bounds[0] && window.scrollY <= bounds[1]) {
          if (!isFixed) {
            setOffset(contentRef.current?.getBoundingClientRect().top)
            setIsFixed(true)
          }
        } else {
          if (isFixed) {
            if (window.scrollY >= bounds[1]) {
              setOffset(bounds[1] - bounds[0])
            } else if (window.scrollY <= bounds[0]) {
              setOffset(0)
            }
          }
          setIsFixed(false)
        }
      }
    }
    function handle() {
      requestAnimationFrame(checkScroll)
    }
    window.addEventListener('scroll', handle)
    return () => window.removeEventListener('scroll', handle)
  }, [contentRef, shouldScroll, bounds, isFixed])

  const setStickyBounds = () => {
    if (
      window.innerWidth >= breakpoints.mlg &&
      stickyParentRef &&
      stickyParentRef.current &&
      contentRef &&
      contentRef.current
    ) {
      const paddingBottom = 200
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const { top, height } = stickyParentRef.current.getBoundingClientRect()

      const contentHeight = contentRef.current.getBoundingClientRect().height

      const offsetTop = top + scrollTop - 100
      const offsetBottom = offsetTop + (height - paddingBottom) - contentHeight

      setBounds([offsetTop, offsetBottom])
    }
  }

  useIsomorphicLayoutEffect(() => {
    window.setTimeout(setStickyBounds, 100)
    window.addEventListener('resize', setStickyBounds)
    return () => window.removeEventListener('resize', setStickyBounds)
  }, [])

  return (
    <Container>
      <Content>
        <StickyContent
          ref={contentRef}
          style={{
            position: shouldScroll && isFixed ? 'fixed' : 'relative',
            top: `${shouldScroll ? offset : 0}px`,
          }}
        >
          <Type
            as="h2"
            preset="headlineExtraLarge"
            bottom={{ xs: 0.5, md: 1.5 }}
          >
            {title}
          </Type>
          <Type multiline preset="subtitle" maxWidth={435}>
            {description}
          </Type>
          {link &&
            (link.fields.isButton ? (
              <Button
                to={link.fields.url}
                paddingSize="small"
                maxWidth={{ xs: 200 }}
                style={{ flex: 1 }}
                top={{ xs: 1, md: 2.5 }}
              >
                {link.fields.label}
              </Button>
            ) : (
              <ArrowLink to={link.fields.url}>
                <Type
                  size={{ xs: 14, md: 18 }}
                  weight="bold"
                  top={{ xs: 1, md: 2.5 }}
                >
                  {link.fields.label}
                </Type>
                <LinkArrowWrapper>
                  <LinkArrow width={isMobile ? 12 : 14} height={10} />
                </LinkArrowWrapper>
              </ArrowLink>
            ))}
        </StickyContent>
      </Content>
      <Sidebar>
        <Cards>
          {cards.map(item => (
            <CardCollectionCard
              key={item.title}
              title={item.title}
              price={item.price}
              description={item.description}
              link={item.link}
              linkLabel={item.linkLabel}
              image={item.image}
              imageRatio={isMobile ? 160 / 144 : 320 / 272}
            />
          ))}
        </Cards>
      </Sidebar>
      <ImageDecorator gradient={gradient} ref={stickyParentRef} />
    </Container>
  )
}
