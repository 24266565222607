import get from 'lodash/get'
import snakeCase from 'lodash/snakeCase'

import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { triggerEvent } from './events'
import type { ViewItemList } from './types/ecommerce'

interface ProductReference {
  fields: {
    productId: string
    name: string
    brand: string
    category: string
    list: string
    price?: {
      fields: {
        isk?: number
        title: string
        priceFormat?: string
      }
    }
  }
}

type ProductImpression = {
  event: ViewItemList['event']
  impressions: ViewItemList['ecommerce']['items']
}

const extractProductImpressions = (
  productReferences: ProductReference[],
  iskEurExchangeRate: number
): ViewItemList['ecommerce']['items'] => {
  const result: Record<string, ProductImpression['impressions']> = {}

  for (const productReference of productReferences) {
    const { productId, brand, category, list, name, price } =
      productReference.fields

    let priceValue = 0
    if (price?.fields.isk) {
      priceValue = calcPrice(price.fields.isk, iskEurExchangeRate) ?? 0
    }

    if (result[category] === undefined) {
      result[category] = []
    }

    result[category].push({
      item_id: productId,
      item_brand: brand,
      item_category: category,
      item_list_name: list,
      item_list_id: snakeCase(list),
      item_name: name,
      price: priceValue,
      index: result[category].length,
      currency: 'EUR',
      quantity: 1,
    })
  }

  return Object.keys(result).flatMap(key => result[key])
}

export const triggerProductImpressions = (
  productReferences: ProductReference[],
  iskEurExchangeRate: number
) => {
  const productImpressions = extractProductImpressions(
    productReferences,
    iskEurExchangeRate
  )

  triggerEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: snakeCase(productReferences?.[0]?.fields?.list),
      item_list_name: productReferences?.[0]?.fields?.list,
      items: productImpressions,
    },
  })
}

export const triggerProductClick = (
  productReference: ProductReference,
  iskEurExchangeRate: number
) => {
  const {
    productId,
    brand,
    category,
    name,
    price: priceEntry,
    list,
  } = productReference.fields

  const price = get(priceEntry, 'fields.isk', 0)

  triggerEvent({
    event: 'select_item',
    ecommerce: {
      item_list_id: snakeCase(list),
      item_list_name: list,
      currency: 'EUR',
      items: [
        {
          item_id: productId,
          item_name: name,
          item_brand: brand,
          item_category: category,
          item_list_id: snakeCase(list),
          item_list_name: list,
          currency: 'EUR',
          price: calcPrice(price, iskEurExchangeRate) ?? 0,
          quantity: 1,
        },
      ],
    },
  })
}
