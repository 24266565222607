import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isBefore } from 'date-fns/isBefore'
import { startOfToday } from 'date-fns/startOfToday'

import type { IPagePackageFields } from 'contentful-shared'
import { Amount } from 'bl-common/src/elements/Amount'
import { Type } from 'bl-common/src/elements/Typography/Typography'

import { getEntryWrapper } from 'services/contentfulClient'

import { type FragmentType, graphql, useFragment } from '../../../../gql'
import * as styles from './styles'

export const GiftCardFragment = graphql(`
  fragment GiftCardFragment on GiftCardGetGiftCardResponse {
    number
    status
    validTo
    externalDocumentNo
    amount
  }
`)

const today = startOfToday()

export const BalanceResult = (props: {
  giftcard: FragmentType<typeof GiftCardFragment>
}) => {
  const { t, i18n } = useTranslation()
  const [giftcardContent, setGiftcardContent] =
    useState<IPagePackageFields>(null)
  const giftcard = useFragment(GiftCardFragment, props.giftcard)
  const isAmountGiftcard =
    giftcard.externalDocumentNo === '2lRuuzoGK70uJwXZb8kWJH'

  useEffect(() => {
    // We don't need title for amount giftcards
    if (isAmountGiftcard) return
    const fetchGiftcardContent = async () => {
      const response = await getEntryWrapper<IPagePackageFields>(
        giftcard.externalDocumentNo,
        { locale: i18n.language }
      )
      if (response?.fields) {
        setGiftcardContent(response.fields)
      }
    }
    fetchGiftcardContent()
  }, [giftcard])

  const isGiftcardExpired =
    giftcard.validTo && isBefore(new Date(giftcard.validTo), today)

  return (
    <styles.BalanceWrapper animate={{ opacity: 1 }}>
      <Type size={{ md: 24 }} top={{ xs: 1, md: 1.5 }}>
        {isGiftcardExpired ? (
          t('giftCardExpired')
        ) : giftcard.status === 'Blocked' || giftcard.status === 'Used' ? (
          t('giftCardInvalid')
        ) : giftcardContent ? (
          <>
            {giftcardContent.title}
            {' ('}
            <Amount value={giftcard.amount} />
            {')'}
          </>
        ) : (
          <>
            {`${t('balance')}: `}
            <Amount value={giftcard.amount} />
          </>
        )}
      </Type>
    </styles.BalanceWrapper>
  )
}
